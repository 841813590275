import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { CookieHelper } from '@core/helpers/cookie.helper';
import { Observable } from 'rxjs';
import { BodyHelper } from '@core/helpers/body.helper';
import { DarkModeService } from '@core/services/dark-mode.service';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  constructor(private _darkModeService: DarkModeService) {}

  get darkMode$(): Observable<boolean> {
    return this._darkModeService.darkMode$;
  }

  /**
   * Runs with APP_INITIALIZER
   */
  serviceInit(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._initDarkMode();
      this._listenToEvents();
      resolve();
    });
  }

  toggle(): void {
    this._darkModeService.toggle();
  }

  private _initDarkMode(): void {
    if (CookieHelper.getCookie('darkMode')?.split('=')[1] === 'true') {
      this._darkModeService.enable();
    } else {
      this._darkModeService.disable();
    }
  }

  private _listenToEvents(): void {
    this._darkModeService.darkMode$
      .pipe(
        tap((darkMode: boolean) => {
          BodyHelper.removeClass('transition-prevention');
          CookieHelper.setCookie('darkMode', darkMode ? 'true' : 'false');
        }),
      )
      .subscribe();
  }
}
