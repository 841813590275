import { BehaviorSubject, Observable } from 'rxjs';
import { Inject, Injectable, Optional, RendererFactory2 } from '@angular/core';
import { DarkModeOptions } from '@interfaces/dark-mode-options.interface';
import { DOCUMENT } from '@angular/common';
import { DARK_MODE_OPTIONS } from '@core/constants/dark-mode-options.constant';

@Injectable({
  providedIn: 'root',
})
export class DarkModeService {
  private _darkModeSubject$: BehaviorSubject<boolean>;
  private readonly _defaultOptions: DarkModeOptions;
  private readonly _options;
  private _renderer;
  private _storage;

  constructor(
    @Inject(DOCUMENT)
    private _documentRef: Document,
    rendererFactory: RendererFactory2,
    @Optional() @Inject(DARK_MODE_OPTIONS) providedOptions: DarkModeOptions,
  ) {
    this._defaultOptions = {
      darkModeClass: 'dark-mode',
      lightModeClass: 'light-mode',
      preloadingClass: 'dark-mode-preloading',
      storageKey: 'dark-mode',
      element: this._documentRef.body,
    };
    this._options = { ...this._defaultOptions, ...(providedOptions || {}) };
    this._renderer = rendererFactory.createRenderer(null, null);
    this._storage = this._documentRef.defaultView?.localStorage;
    this._darkModeSubject$ = new BehaviorSubject(
      this.getInitialDarkModeValue(),
    );
    this._darkModeSubject$.getValue() ? this.enable() : this.disable();
  }

  private saveToStorage(darkMode: boolean): void {
    this._storage!.setItem(
      this._options.storageKey,
      JSON.stringify({ darkMode }),
    );
  }

  private getFromStorage(): boolean | null {
    const storageItem = this._storage?.getItem(this._options.storageKey);

    if (storageItem) {
      try {
        return JSON.parse(storageItem)?.darkMode;
      } catch (error) {
        console.error(
          'Invalid darkMode localStorage item:',
          storageItem,
          'falling back to color scheme media query',
        );
      }
    }

    return null;
  }

  private getInitialDarkModeValue(): boolean {
    const darkModeFromStorage = this.getFromStorage();

    if (this.isNil(darkModeFromStorage)) {
      return !!this._documentRef.defaultView?.matchMedia(
        '(prefers-color-scheme: dark)',
      ).matches;
    }

    return darkModeFromStorage;
  }

  private isNil(value: any): value is null | undefined {
    return value === null || value === undefined;
  }

  get darkMode$(): Observable<boolean> {
    return this._darkModeSubject$.asObservable();
  }

  toggle(): void {
    this._darkModeSubject$.getValue() ? this.disable() : this.enable();
  }

  enable(): void {
    const { element, darkModeClass, lightModeClass } = this._options;
    this._renderer.removeClass(element, lightModeClass);
    this._renderer.addClass(element, darkModeClass);
    this.saveToStorage(true);
    this._darkModeSubject$.next(true);
  }

  disable(): void {
    const { element, darkModeClass, lightModeClass } = this._options;
    this._renderer.removeClass(element, darkModeClass);
    this._renderer.addClass(element, lightModeClass);
    this.saveToStorage(false);
    this._darkModeSubject$.next(false);
  }
}
