import { EnvironmentHelper } from '@core/helpers/environment.helper';

export class CookieHelper {
  /**
   * TODO: Implement key search
   */
  static getCookie(key: string): string | undefined {
    return document.cookie;
  }

  static setCookie(key: string, value: string): void {
    document.cookie =
      key + '=' + value + '; Domain=' + EnvironmentHelper.getHost();
  }
}
