import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { EnvironmentNames } from '@environments/environment-names';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentHelper {
  static isProduction(): boolean {
    return environment.type === EnvironmentNames.PRODUCTION;
  }

  /**
   * Generates URL for a given path to the front end (based on config)
   */
  static getFrontEndURL(path: string, subdomain?: string): string {
    let url = environment.frontEnd.protocol + '://';

    if (subdomain) {
      url += subdomain + '.';
    }

    url += environment.frontEnd.host;
    url += path;

    return url;
  }

  static getHandbookUrl(): string {
    return EnvironmentHelper.getFrontEndURL('', 'bokami');
  }

  static getHost(includePort = false): string {
    let host = environment.frontEnd.host;

    if (!includePort) {
      host = host.split(':')[0];
    }

    return host;
  }
}
