import { EnvironmentNames } from '@environments/environment-names';

export const environment = {
  type: EnvironmentNames.STAGING,
  frontEnd: {
    host: 'halloverden.dev',
    protocol: 'https',
  },
  instagram: {
    token: '',
  },
  directus: {
    host: 'https://api.halloverden.dev',
    graphqlEndpoint: '/graphql',
    assetsPath: '/assets'
  },
  router: {
    debug: false,
  },
};
